import React, { Fragment } from 'react';
import { graphql, Link as GatsbyLink, navigate, PageRendererProps } from 'gatsby';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, GridList, GridListTile, GridListTileBar, Paper, Typography } from '@material-ui/core';
import { AddToCartButton } from '../components/Button/AddToCartButton';
import { Card } from '../components/Card/Card';
import { CardHeader } from '../components/Card/CardHeader';
import { CardBody } from '../components/Card/CardBody';
import classNames from 'classnames';
import { indexStyle } from '../assets/jss/pages/indexStyle';
import Img from 'gatsby-image';
import { BlogPostNode, BlogPostSimple } from '../components/BlogPost/BlogPost';
import { Parallax } from '../components/Parallax/Parallax';
import { DefaultHeader } from '../components/Header/Header';
import { AppearingAtNode, CategoryNode, ProductNode } from '../interfaces/contentful';
import { FormattedMessage, useIntl } from 'react-intl';
import { Price } from '../components/Price/Price';
import { Helmet } from 'react-helmet';

export const indexQuery = graphql`
  query {
    mainCategoryList: contentfulCategoryList(slug: { eq: "main-category-list" }) {
      slug
      categories {
        contentful_id
        slug
        title
        cover {
          fixed(width: 217, height: 217, quality: 90) {
            ...GatsbyContentfulFixed
          }
        }
      }
    }
    latestProducts: allContentfulAppearingAt(sort: { fields: [year, month], order: [DESC, DESC] }) {
      edges {
        node {
          month
          year
          product {
            contentful_id
            slug
            title
            description {
              description
            }
            price
            discountedPrice
            node_locale
            featured
            cover {
              fluid(maxWidth: 250, quality: 80) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
    featuredProducts: allContentfulProduct(
      filter: { featured: { eq: true } }
      limit: 12
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          contentful_id
          slug
          title
          price
          discountedPrice
          node_locale
          featured
          cover {
            fluid(maxWidth: 250, quality: 80) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            description
          }
        }
      }
    }
    allBlogPosts: allContentfulBlogPost(limit: 3) {
      edges {
        node {
          contentful_id
          title
          slug
          content {
            json
          }
          cover {
            fluid(maxWidth: 300, quality: 90) {
              ...GatsbyContentfulFluid
            }
          }
          teaser {
            teaser
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(indexStyle);

const Index: React.FC<IndexProps> = ({ data }) => {
  const { mainCategoryList, featuredProducts, allBlogPosts, latestProducts } = data;

  // const dispatch = useContext(CartDispatchContext);
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  const fewLatestProducts: {
    [id: string]: {
      product: ProductNode;
      month: number;
      year: string;
    };
  } = {};

  let latestMonthYear: string | null = null;

  // TODO: revise this latest products, before was sth with the index=0
  // Latest products means the first AppearingAt month-year combination that has any products in there.
  latestProducts.edges
    .filter((node) => Boolean(node.node.product) && node.node.product.length > 0)
    .forEach(({ node }) => {
      node.product.forEach((productNode, index) => {
        if (!fewLatestProducts[productNode.contentful_id]) {
          if (latestMonthYear === null) {
            latestMonthYear = `${node.month}_${node.year}`;

            fewLatestProducts[productNode.contentful_id] = {
              product: productNode,
              month: node.month,
              year: node.year,
            };
          }

          if (latestMonthYear === `${node.month}_${node.year}`) {
            fewLatestProducts[productNode.contentful_id] = {
              product: productNode,
              month: node.month,
              year: node.year,
            };
          }
        }
      });
    });

  const addStylesForRotatingCards = () => {
    const rotatingCards = document.getElementsByClassName(classes.cardRotate);
    for (let i = 0; i < rotatingCards.length; i++) {
      const rotatingCard = rotatingCards[i]!;
      const cardFront = rotatingCard.getElementsByClassName(classes.front)[0]! as HTMLDivElement;
      const cardBack = rotatingCard.getElementsByClassName(classes.back)[0]! as HTMLDivElement;
      cardFront.style.height = 'unset';
      cardFront.style.width = 'unset';
      cardBack.style.height = 'unset';
      cardBack.style.width = 'unset';
      const rotatingWrapper = rotatingCard.parentElement! as HTMLDivElement;
      const cardWidth = rotatingWrapper.offsetWidth;
      const cardHeight = (rotatingCard.children[0].children[0] as HTMLDivElement).offsetHeight;
      rotatingWrapper.style.height = cardHeight + 'px';
      rotatingWrapper.style['margin-bottom' as any] = 25 + 'px';
      cardFront.style.height = 'unset';
      cardFront.style.width = 'unset';
      cardBack.style.height = 'unset';
      cardBack.style.width = 'unset';
      cardFront.style.height = cardHeight + 35 + 'px';
      cardFront.style.width = cardWidth + 'px';
      cardBack.style.height = cardHeight + 35 + 'px';
      cardBack.style.width = cardWidth + 'px';
    }
  };

  React.useEffect(() => {
    if (window) {
      window.addEventListener('resize', addStylesForRotatingCards);
    }
    addStylesForRotatingCards();
    return (): void => {
      if (window) {
        window.removeEventListener('resize', addStylesForRotatingCards);
      }
    };
  }, [featuredProducts]);

  return (
    <Fragment>
      <Helmet>
        <title>{intl.formatMessage({ id: 'main.parallax.title' })}</title>
      </Helmet>
      <DefaultHeader />

      <Parallax image={require('../assets/img/bg2.jpg')} className={classes.parallax}>
        <div className={classes.container}>
          <div className={classes.brand}>
            <h1>
              <FormattedMessage id="main.parallax.title" defaultMessage="Chinese Books" />
            </h1>
            <h2 className={classes.title}>
              <FormattedMessage id="main.parallax.subtitle" defaultMessage="The best books in NL" />
            </h2>
          </div>
        </div>
      </Parallax>
      <div className={classNames(classes.section, classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Typography variant="h5" component="h2" gutterBottom>
            <FormattedMessage id="main.categories" defaultMessage="Categories" />
          </Typography>

          <Paper className={classes.gridListWrapper}>
            <GridList spacing={4} cellHeight={217} className={classes.gridList} cols={5}>
              {mainCategoryList.categories.map((node) => {
                return (
                  <GridListTile key={node.contentful_id} style={{ width: '221px' }}>
                    {/*<GatsbyLink to={`/categories/${node.slug}`}></GatsbyLink>*/}
                    {/*TODO: if wrapped in something, than the img does not scale... */}
                    <img
                      src={node.cover.fixed.src}
                      alt={node.title}
                      className={classes.gridListImage}
                      onClick={async (e): Promise<void> => {
                        e.preventDefault();
                        await navigate(`/categories/${node.slug}`);
                      }}
                    />
                    <GridListTileBar
                      titlePosition="bottom"
                      title={node.title}
                      style={{ textTransform: 'uppercase' }}
                      // className={classes.gridListImage}
                      // subtitle={'category'}
                      classes={{ root: classes.gridListTitleBar, title: classes.gridListTitleBarTitle }}
                      // actionIcon={
                      //   <GatsbyLink to={`/categories/${node.slug}`}>
                      //     <IconButton aria-label="star" style={{ color: 'white' }}>
                      //       <SubdirectoryArrowRight />
                      //     </IconButton>
                      //   </GatsbyLink>
                      // }
                    />
                  </GridListTile>
                );
              })}
            </GridList>
          </Paper>

          <br />
          <Typography variant="h5" component="h2" gutterBottom>
            <FormattedMessage id="main.featuredProducts" defaultMessage="Featured products" />
          </Typography>

          <Grid container spacing={2} justify="center">
            {featuredProducts.edges.map(({ node: product }) => {
              return (
                <Grid key={product.contentful_id} item md={3} sm={5} xs={10}>
                  <div className={classes.rotatingCardContainer}>
                    {/* ROTATING CARD */}
                    <Card className={classes.cardRotate}>
                      <div className={classes.front}>
                        <CardBody className={`${classes.cardBodyRotate}`}>
                          <Img sizes={{ ...product.cover.fluid, aspectRatio: 1 / 1 }} />
                          {/*<h4 className={classes.cardTitle}>{product.title}</h4>*/}
                        </CardBody>
                      </div>
                      <div className={classes.back}>
                        <CardBody className={classes.cardBodyRotate}>
                          <GatsbyLink to={`/products/${product.slug}`}>
                            <h4 className={classes.cardTitle}>{product.title}</h4>
                          </GatsbyLink>

                          <p className={classes.cardDescription}>
                            {product.description ? `${product.description.description.substring(0, 50)}...` : ''}
                          </p>
                          <Price product={product} />
                          <AddToCartButton product={product} quantity={1} />
                        </CardBody>
                      </div>
                    </Card>
                  </div>
                </Grid>
              );
            })}
          </Grid>

          <br />
          <br />
          <Typography variant="h5" component="h2" gutterBottom>
            <FormattedMessage id="main.latestProducts" defaultMessage="Latest products" />
          </Typography>

          <Paper style={{ padding: theme.spacing(2) }}>
            <Grid container justify="flex-start" spacing={1}>
              {Object.values(fewLatestProducts).map((node, index) => {
                return (
                  <Grid item key={node.product.contentful_id} md={2} sm={4} xs={6}>
                    <Card product>
                      <CardHeader image plain style={{ textAlign: 'center', padding: '10px' }}>
                        <GatsbyLink to={`/products/${node.product.slug}`}>
                          <Img sizes={{ ...node.product.cover.fluid, aspectRatio: 1 / 1 }} />
                        </GatsbyLink>
                      </CardHeader>
                      <CardBody
                        className={classes.textCenter}
                        plain
                        data-appeared-at={JSON.stringify({ month: node.month, year: node.year })}
                      >
                        <h4 className={classes.cardTitle} style={{ minHeight: '40px' }}>
                          <GatsbyLink to={`/products/${node.product.slug}`}>{node.product.title}</GatsbyLink>
                        </h4>
                        <Price product={node.product} />
                        <br />
                        <AddToCartButton product={node.product} quantity={1} size="sm" onlyIcon={true} />
                      </CardBody>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Paper>
        </div>
      </div>
      {/*TODO: BringBackPosts*/}
      <div className={classes.container}>
        <br />
        <br />
        {/*  <Typography variant="h5" component="h2">*/}
        {/*    <FormattedMessage id="main.recentBlogPosts" defaultMessage="Recent Blog Posts" />*/}
        {/*  </Typography>*/}

        {/*  <Grid container justify="center" className={classes.gridContainer} spacing={3}>*/}
        {/*    {allBlogPosts.edges.map(({ node }) => {*/}
        {/*      return (*/}
        {/*        <Grid key={node.contentful_id} item xs={10} sm={4} md={4}>*/}
        {/*          <BlogPostSimple {...node} />*/}
        {/*        </Grid>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*  </Grid>*/}
      </div>
    </Fragment>
  );
};

export default Index;

interface IndexProps extends PageRendererProps {
  data: {
    latestProducts: {
      edges: [
        {
          node: AppearingAtNode;
        },
      ];
    };
    mainCategoryList: {
      categories: CategoryNode[];
    };
    featuredProducts: {
      edges: [
        {
          node: ProductNode;
        },
      ];
    };
    allBlogPosts: {
      edges: [
        {
          node: BlogPostNode;
        },
      ];
    };
  };
}
